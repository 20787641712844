<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "FindingAndWorkingWithABetaReader",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FindingAndWorkingWithABetaReader">
      <SuawParagraph
        text="So you’ve finished your first draft. Congratulations! Take a deep breath and celebrate this victory. But don’t break out the champagne just yet. Brace yourself, because it’s time to let your draft out into the world and into the hands of readers who can give you feedback. But who should this audience be, and how will you find them?"
      />
      <SuawParagraph text="There are many different ways to do this, but one of the best strategies is to look for <b>beta readers</b>." />
      <SuawHeading level="3" content="What is a beta reader?" />
      <SuawParagraph
        text="You may have heard of “beta testers:” people who test software before it’s released to the general public in order to find errors. That’s where the term “beta reader” comes from."
      />
      <SuawParagraph
        text="I first heard the term many years ago, when I first started writing fanfiction, and thought it was specific to the online fanfic community. Turns out that beta reading is a strategy used by the writing community in general, and is usually considered to be an essential part of the editing process."
      />
      <SuawParagraph
        text="Think of beta readers as the test audience for your book. They enter the picture once your first draft is complete, and approach your draft as if it was something they picked up randomly at the bookstore. It’s not a beta reader’s job to provide grammar and style edits, or help you restructure a tricky plot arc. A good beta reader will offer overall impressions, emotional reactions, and other feedback that will help you understand how a casual reader might react to your work."
      />
      <SuawParagraph text="Why is a beta reader essential? Even if you’re an excellent self-editor, you will never have an outside perspective on your own work." />
      <SuawParagraph
        text="(By the way, if you sought help and opinions from outside readers before your draft was finished, you can think of those earlier readers as <b>alpha readers</b>.)"
      />
      <SuawHeading level="3" content="How much does beta reading cost?" />
      <SuawParagraph
        text="Unlike professional editing services, beta reading is most often done for free as a favor to the author. However, many writers offer free copies of their finished books to their beta readers, or acknowledge this favor by thanking them in another way. Cookies? Flowers? We’ll let you be creative. Your beta reader may be lucky to get a sneak preview of your book, but you’re even luckier to have a willing audience ready to give you a fresh take on your piece. Don’t let this wonderful favor go unacknowledged."
      />
      <SuawHeading level="3" content="How do I find a beta reader?" />
      <SuawParagraph
        text="Because beta reading is usually done for free, many authors start their search by asking friends and family. If you’ve already been working with particular friends or family members during the drafting process, you may want to recruit new volunteers for beta reading. It’s important that your beta readers approach your work with fresh eyes, and even better if you have told them very little about the draft beforehand. That way, they won’t have any preconceived ideas about your project before they start reading."
      />
      <SuawParagraph
        text="If friends or family are not available to read, there are plenty of places to search for beta readers. Joining a writing community like Shut Up &amp; Write is a great place to start. Maybe you have a friend from a regular writing event who might be willing to read for you? This is an excellent way to trade favors with fellow writers, and yet another reason why writing communities are such a valuable resource. Connecting with fellow writers is not only helpful during the drafting process, but can become even more valuable during the editing stage. Not only that, a fellow writer who is familiar with your process may have a good sense of what kind of feedback might be helpful to you."
      />
      <SuawParagraph text="Where to find a beta reader online:" />
      <SuawParagraph
        text="<ul><li><div><a target='_blank' href='https://www.goodreads.com/group/show/50920-beta-reader-group'>Goodreads Beta Reader Group</a></div></li><li><div><a target='_blank' href='https://www.facebook.com/groups/1782619931753141/'>Beta Readers &amp; Critique Partners Facebook Group</a></div></li><li><div><a target='_blank' href='http://critters.org/'>Critters Speculative Writers Workshop</a></div></li><li><div><a target='_blank' href='https://www.writing.com/'>Writing.com</a></div></li></ul>"
      />
      <SuawHeading level="3" content="How many beta readers do I need?" />
      <SuawParagraph
        text="Because readers are as individual as writers, it’s a good idea to line up multiple beta readers. Every beta reader brings a unique perspective to their reading experience, and the more feedback you receive, the more you’ll understand your own work. If your draft is a sci-fi thriller, you may get one reaction from a fellow sci-fi buff, and a different reaction from a friend who prefers historical fiction. Both reactions can be helpful, so keep variety in mind when you search for your readers."
      />
      <SuawParagraph
        text="Based on the subject matter of your draft, you may also want to consider seeking out a <a href='https://blog.reedsy.com/sensitivity-readers/' target='_blank'>sensitivity reader</a>. A sensitivity reader is a specialized beta reader who looks at your draft with an eye toward cultural stereotypes and other problems with bias or representation."
      />
      <SuawHeading level="3" content="How do I work with a beta reader?" />
      <SuawParagraph
        text="The most important thing to remember when working with any outside reader is to be as clear as possible about your needs. Your friends may not understand that correcting every last comma is not necessary at this stage. (You’ll pay a proofreader to do that later.) Your mom may have a hard time giving you genuine criticism when you need her honest opinion. A fellow writer may take a deep dive into writing technicalities when you are looking for a gut reaction. Speaking up about the type of feedback you need from each of your beta readers is essential."
      />
      <SuawParagraph
        text="I learned this lesson the hard way in 5th grade, when I decided to pursue my novelist dreams by writing a tale about talking kittens. I wrote three entire chapters in my best cursive handwriting and handed them shyly to my teacher, hoping to get her expert opinion. What I was asking for was a beta reader, but I didn’t know that. Neither did my teacher; she probably thought I was looking for extra credit. One week later, she handed back my draft with a circled grade on it, but no comments. I was crushed. Didn’t my teacher know how to read my mind? Lesson learned: When you hand over a draft, set clear expectations on both sides."
      />
      <SuawParagraph
        text="So, remember to do your homework for your readers — and maybe some extra credit, too. It can be really helpful to write down a list of questions for your readers to answer about your piece. Did they find your story exciting, or did they fall asleep reading it? Did they solve the mystery before your detective had a chance? Were those talking kittens believable as three-dimensional characters?"
      />
      <SuawParagraph
        text="Also, don’t forget to set a deadline. It can be awkward to be left waiting, or to feel like someone rushed through your draft to get it back to you. It’s easier for everyone if you set a due date, even if there’s no hurry."
      />
      <SuawHeading level="3" content="My beta readers are done. What now?" />
      <SuawParagraph
        text="Assuming you thanked your readers profusely (wine, candy, etc.), the first thing to remember is that your beta readers’ opinions are just that: opinions. They can be incredibly useful, but you don’t have to treat them like a set of instructions. When you receive your readers’ feedback, carefully consider their comments, and decide which comments will become action items, and which comments are less helpful. It’s also important to look at your readers’ responses as a whole — yet another reason why it’s important to have more than one beta reader. Their collective reactions should give you valuable guidance on both the negative and positive in your draft."
      />
      <SuawParagraph
        text="From here, it’s time to go back to your draft and incorporate the things you learned from your readers. Brace yourself: sometimes, this isn’t easy emotionally. It can be difficult to take criticism,"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
